import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "columns is-multiline" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TheService = _resolveComponent("TheService")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.reasons, ([title, content], key) => {
      return (_openBlock(), _createBlock("div", {
        class: "column is-half",
        key: key
      }, [
        _createVNode(_component_TheService, {
          title: _ctx.$t(title),
          content: _ctx.$t(content)
        }, null, 8, ["title", "content"])
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}