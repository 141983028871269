
import { defineComponent } from "vue";
import { enUS, zhCN, isZhCN } from "@/plugins/i18n";
import VImage from "@/components/Image.vue";
import VSection from "@/components/VSection/index.vue";
import TheAsrs from "./TheAsrs.vue";
import TheDevices from "./TheDevices.vue";
import TheHero from "./TheHero.vue";
import TheHoister from "./TheHoister.vue";
import TheServices from "./TheServices.vue";
import TheSoftware from "./TheSoftware.vue";

export default defineComponent({
  name: "HomeIndex",

  components: {
    TheAsrs,
    TheDevices,
    TheHero,
    TheHoister,
    TheServices,
    TheSoftware,
    VImage,
    VSection,
  },

  data: () => ({
    height: "0.75rem",
    partners: [
      "招商局", "格力", "武汉万吨",
      "中外运", "世纪联华", "鑫荣懋",
      "鲜丰水果", "美源供应链", "鲁海丰"
    ],
  }),

  computed: {
    isZhCN,
  },

  methods: {
    handleJump () {
      window.scrollTo(0, 0);
    }
  },

  i18n: {
    messages: {
      [zhCN]: {
        business: "我们的服务",
        partners: "合作伙伴",
        products: "产品介绍",
      },
      [enUS]: {
        business: "Our Business",
        partners: "Partners",
        products: "Products"
      }
    }
  }
});
