import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "app-index" }
const _hoisted_2 = { class: "columns is-multiline" }
const _hoisted_3 = { class: "box" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TheHero = _resolveComponent("TheHero")
  const _component_TheServices = _resolveComponent("TheServices")
  const _component_VSection = _resolveComponent("VSection")
  const _component_VImage = _resolveComponent("VImage")
  const _component_TheHoister = _resolveComponent("TheHoister")
  const _component_TheAsrs = _resolveComponent("TheAsrs")
  const _component_TheDevices = _resolveComponent("TheDevices")
  const _component_TheSoftware = _resolveComponent("TheSoftware")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_TheHero),
    _createVNode(_component_VSection, {
      title: _ctx.$t('business')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_TheServices)
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_VSection, {
      title: _ctx.$t('partners'),
      style: {"padding-top":"0"}
    }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.partners, (item, key) => {
            return (_openBlock(), _createBlock("div", {
              key: key,
              class: "column is-one-third is-flex is-centered"
            }, [
              _createVNode("div", _hoisted_3, [
                _createVNode(_component_VImage, {
                  path: `home/partners/${item}.png`,
                  style: {"width":"320px","height":"160px"}
                }, null, 8, ["path"])
              ])
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_VSection, {
      title: _ctx.$t('products'),
      background: "white-ter"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_TheHoister, { onClickMore: _ctx.handleJump }, null, 8, ["onClickMore"])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_VSection, { background: "white" }, {
      default: _withCtx(() => [
        _createVNode(_component_TheAsrs, { onClickMore: _ctx.handleJump }, null, 8, ["onClickMore"])
      ]),
      _: 1
    }),
    _createVNode(_component_VSection, { background: "white-ter" }, {
      default: _withCtx(() => [
        _createVNode(_component_TheDevices, { onClickMore: _ctx.handleJump }, null, 8, ["onClickMore"])
      ]),
      _: 1
    }),
    _createVNode(_component_VSection, { background: "white" }, {
      default: _withCtx(() => [
        _createVNode(_component_TheSoftware, { onClickMore: _ctx.handleJump }, null, 8, ["onClickMore"])
      ]),
      _: 1
    })
  ]))
}