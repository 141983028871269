
import { defineComponent } from "vue";

export default defineComponent({
  name: "Feature",

  props: {
    title: String,
    content: String,
  }
});
